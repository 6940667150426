import React from "react";
import { Box, Typography } from "@mui/material";
import { variables } from "../../theme/variables";
import { specialData } from "../../utils/constant";
import FeaturesSlider from "./FeaturesSlider";

function Features() {
  return (
    <Box bgcolor={variables.black} id="features" className="px-4 md:px-0">
      <Box className="w-full  ml-6 md:ml-0 flex justify-start md:justify-center">
        <Typography
          className="pt-16 md:pt-[120px] pb-8 md:text-center"
          variant="h3"
          fontWeight={500}
          sx={{
            backgroundImage: variables.secondaryColor,
            WebkitBackgroundClip: "text",
            color: "transparent",
            display: "inline-block",
          }}
        >
          Our Special Features
        </Typography>
      </Box>
      <Box>
        <Box className="md:hidden">
          <FeaturesSlider />
        </Box>
        <Box className="hidden md:block">
          {specialData.map((item, key) => {
            return (
              <Box
                key={key}
                className="grid grid-cols-2 mt-4"
                sx={{
                  background: `linear-gradient(${
                    item.position === "left" ? "90deg," : "270deg,"
                  } rgba(7,9,20,1) 0%, rgba(46,44,75,1) 100%)`,
                }}
              >
                <Box
                  className={`${
                    item.position === "right" ? "hidden" : "flex justify-center"
                  }`}
                >
                  <img
                    className="max-w-[315px]"
                    src={item.image}
                    alt="features"
                  />
                </Box>
                <Box
                  className={`flex flex-col justify-center ${
                    item.position === "left" ? "items-start" : "items-end"
                  }`}
                >
                  <Box className="max-w-[570px]">
                    <Typography className="pb-3" variant="h2" fontWeight={700}>
                      {item.title}
                    </Typography>
                    <Typography className="pb-6" variant="h6" fontWeight={400}>
                      {item.subtitle}
                    </Typography>
                    <Typography variant="h6" fontWeight={300}>
                      {item.after}
                      <span className="font-light text-primaryColor">
                        {item.redText}
                      </span>
                      {item.before}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  className={`${
                    item.position === "left" ? "hidden" : "flex justify-center"
                  }`}
                >
                  <img
                    className="max-w-[315px]"
                    src={item.image}
                    alt="features"
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default Features;
