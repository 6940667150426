import { variables } from "./variables";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {},
  typography: {
    fontFamily: `"Inter", sans-serif`,
    h2: {
      fontWeight: 600,
      fontSize: 40,
      color: variables.whiteColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 20,
        fontWeight: 700,
      },
    },
    h3: {
      fontWeight: 600,
      fontSize: 36,
      color: variables.secondaryColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 22,
        fontWeight: 500,
      },
    },
    h4: {
      fontWeight: 700,
      fontSize: 32,
      color: variables.primaryColor,
      textAlign: "center",
    },
    h5: {
      fontWeight: 400,
      fontSize: 24,
      color: variables.greyColor,
    },
    h6: {
      fontWeight: 500,
      fontSize: 22,
      color: variables.infoColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 15,
        fontWeight: 400,
      },
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 20,
      color: variables.greyColor,
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 18,
      color: variables.darkGreyColor,
      "@media only screen and (max-width: 800px)": {
        fontSize: 15,
      },
    },
    body1: {
      fontSize: 26,
      fontWeight: 400,
      color: variables.whiteColor,
      lineHeight: "normal",
      "@media only screen and (max-width: 800px)": {
        fontSize: 18,
      },
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      color: variables.whiteColor,
      lineHeight: "normal",
      "@media only screen and (max-width: 800px)": {
        fontSize: 12,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 800,
      lg: 1024,
      xl: 1400,
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
  },
});
