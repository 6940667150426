import React from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { Autoplay } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import SwiperWrapper from "../SwiperWrapper/SwiperWrapper";
import { variables } from "../../theme/variables";
import { Link } from "react-router-dom";

const Categories = ({ categories }) => {
  const matches = useMediaQuery("(min-width:800px)");

  const styles = {
    wrapper: {
      position: "relative",
      marginTop: "-1px",
    },
  };

  let halfLength = Math.ceil(categories?.length / 2);
  let array1 = matches ? categories : categories?.slice(0, halfLength);
  let array2 = categories?.slice(halfLength);

  return (
    <>
      {array1 ? (
        <Box className="mt-[-1px]" id="categories">
          <Box
            className="w-full pb-6  pl-6 md:pl-0 flex justify-start md:justify-center"
            bgcolor={variables.black}
          >
            <Typography
              sx={{
                backgroundImage: variables.secondaryColor,
                WebkitBackgroundClip: "text",
                color: "transparent",
                display: "inline-block",
              }}
              variant="h3"
              fontWeight={500}
              textAlign="center"
            >
              Our Categories
            </Typography>
          </Box>
          <Box sx={styles.wrapper}>
            <SwiperWrapper
              effect="slide"
              slidesPerView={!matches ? 2 : 5}
              spaceBetween={30}
              delay={2000}
              className="mySwiper"
              style={styles}
              loop={true}
              speed={200}
              modules={[Autoplay]}
            >
              {array1.map((category, key) => {
                return (
                  <SwiperSlide
                    key={key}
                    onClick={() => window.fbq("track", "Click_category")}
                  >
                    <Link to={category.shortLink} target="_blank">
                      <Box className="flex items-center py-6 md:py-8">
                        <img
                          width={30}
                          height={30}
                          src={category.icon.url}
                          alt="category"
                          className="mr-4"
                        />
                        <Typography
                          fontSize={15}
                          color={variables.primaryColor}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </SwiperWrapper>
            <Box
              bgcolor={variables.darkBlue}
              className="absolute top-0 h-full w-full"
            ></Box>
          </Box>
        </Box>
      ) : null}
      {!matches && array2 ? (
        <Box className="mt-[-1px]">
          <Box sx={styles.wrapper}>
            <SwiperWrapper
              effect="slide"
              slidesPerView={2}
              spaceBetween={30}
              delay={2000}
              className="mySwiper"
              style={styles}
              loop={true}
              speed={200}
              modules={[Autoplay]}
            >
              {array2.map((category, key) => {
                return (
                  <SwiperSlide
                    onClick={() => window.fbq("track", "Click_category")}
                    key={key}
                  >
                    <Link to={category.shortLink} target="_blank">
                      <Box className="flex items-center py-6 md:py-8">
                        <img
                          width={30}
                          height={30}
                          src={category.icon.url}
                          alt="category"
                          className="mr-4"
                        />
                        <Typography
                          fontSize={15}
                          color={variables.primaryColor}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                    </Link>
                  </SwiperSlide>
                );
              })}
            </SwiperWrapper>
            <Box
              bgcolor={variables.darkBlue}
              className="absolute top-0 h-full w-full"
            ></Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default Categories;
