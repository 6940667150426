import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Autoplay, A11y } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
import SwiperWrapper from "../SwiperWrapper/SwiperWrapper";
import { variables } from "../../theme/variables";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconInstagram from "../../assets/images/Icon_instagram.svg";
import IconTwitter from "../../assets/images/twitter.svg";
import IconFb from "../../assets/images/icon_fb.svg";
import "./index.css";
import { Link } from "react-router-dom";

const TrendingAuthors = ({ topAuthors }) => {
  const matches = useMediaQuery("(max-width:440px)");
  const mobile = useMediaQuery("(max-width:370px)");
  const tablet = useMediaQuery("(max-width:600px)");
  const tablet2 = useMediaQuery("(max-width:800px)");
  const tablet3 = useMediaQuery("(max-width:1200px)");

  const [flippedIndex, setFlippedIndex] = useState(-1);

  const handleToggleFlip = (index, event) => {
    if (event) {
      if (flippedIndex === index) {
        if (event.target.id !== "text") {
          setFlippedIndex(-1);
        }
      } else {
        setFlippedIndex(index);
      }
      event.stopPropagation();
    } else {
      console.warn("Event object is undefined, assuming click outside.");
      setFlippedIndex(-1);
    }
  };

  return (
    <>
      {topAuthors ? (
        <Box
          bgcolor={variables.black}
          className="py-[60px] md:py-[120px] mt-[-1px]"
          id="authors"
        >
          <Box className="w-full pb-8  ml-6 md:ml-0 flex justify-start md:justify-center">
            <Typography
              sx={{
                backgroundImage: variables.secondaryColor,
                WebkitBackgroundClip: "text",
                color: "transparent",
                display: "inline-block",
              }}
              variant="h3"
              fontWeight={500}
              textAlign="center"
            >
              Trending Authors
            </Typography>
          </Box>
          <Box>
            <SwiperWrapper
              effect="slide"
              slidesPerView={
                mobile
                  ? 1.5
                  : matches
                  ? 2
                  : tablet
                  ? 2.3
                  : tablet2
                  ? 3
                  : tablet3
                  ? 3.3
                  : 4
              }
              spaceBetween={mobile ? 0 : 30}
              autoplay={2000}
              className="mySwiper"
              loop={true}
              speed={200}
              modules={[Autoplay, A11y]}
              centeredSlides={true}
              delay={5000}
            >
              {topAuthors.map((item, key) => (
                <SwiperSlide
                  onClick={() => window.fbq("track", "Click_author")}
                  key={key}
                >
                  <Link to={item.shortLink} target="_blank">
                    <Box
                      className={`w-[200px] md:w-[250px] lg:w-[300px] h-[200px] md:h-[300px] lg:h-[300px] rounded-lg container cursor-pointer ${
                        key === flippedIndex ? "flipped" : ""
                      }`}
                      onClick={(event) => handleToggleFlip(key, event)}
                    >
                      <img
                        width="100%"
                        className="h-full  rounded-lg md:rounded-2xl"
                        src={item.user.image.url}
                        alt="author-icon"
                      />
                      {key === flippedIndex && (
                        <Box className="overlay lg:hidden">
                          <Box
                            id="text"
                            className="text"
                            onClick={() => handleToggleFlip(key)}
                          >
                            <Typography fontSize={20}>
                              {item.pen_name}
                            </Typography>
                            <Box className="flex items-center mt-2  mb-3 ">
                              {item.instagram ? (
                                <Box
                                  bgcolor={variables.darkIndigo}
                                  className="w-8 h-8 mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
                                >
                                  <IconButton href={item.instagram}>
                                    <img src={IconInstagram} alt="instagram" />
                                  </IconButton>
                                </Box>
                              ) : null}
                              {item.facebook ? (
                                <Box
                                  bgcolor={variables.darkIndigo}
                                  className="w-8 h-8 mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
                                >
                                  <IconButton href={item.facebook}>
                                    <img src={IconFb} alt="fb" />
                                  </IconButton>
                                </Box>
                              ) : null}
                              {item.twitter ? (
                                <Box
                                  bgcolor={variables.darkIndigo}
                                  className="w-8 h-8 mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
                                >
                                  <IconButton href={item.twitter}>
                                    <img src={IconTwitter} alt="twiter" />
                                  </IconButton>
                                </Box>
                              ) : null}
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                height: "3px",
                                background:
                                  "linear-gradient(90deg, rgba(151, 36, 69, 0) 0%, #972445 49.5%, rgba(151, 36, 69, 0) 100%)",
                              }}
                            ></Box>
                            <Typography
                              color={variables.darkGreyColor}
                              fontWeight={500}
                              fontSize={16}
                              className="pb-1 pt-2"
                            >
                              Author of
                            </Typography>
                            {item.books.slice(0, 3).map((book, key) => {
                              return (
                                <Typography
                                  key={key}
                                  variant="subtitle1"
                                  fontSize={14}
                                  fontWeight={400}
                                >
                                  {book.title}
                                </Typography>
                              );
                            })}
                          </Box>
                        </Box>
                      )}
                      <Box className="overlay hidden lg:flex">
                        <Box className="text">
                          <Typography variant="h3" fontSize={24}>
                            {item.pen_name}
                          </Typography>
                          <Box className="flex items-center mt-2  mb-3 ">
                            {item.instagram ? (
                              <Box
                                bgcolor={variables.darkIndigo}
                                className="w-8 h-8 mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
                              >
                                <IconButton href={item.instagram}>
                                  <img src={IconInstagram} alt="instagram" />
                                </IconButton>
                              </Box>
                            ) : null}
                            {item.facebook ? (
                              <Box
                                bgcolor={variables.darkIndigo}
                                className="w-8 h-8 mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
                              >
                                <IconButton href={item.facebook}>
                                  <img src={IconFb} alt="fb" />
                                </IconButton>
                              </Box>
                            ) : null}
                            {item.twitter ? (
                              <Box
                                bgcolor={variables.darkIndigo}
                                className="w-8 h-8 mr-2 rounded-3xl flex justify-center items-center cursor-pointer social-icon"
                              >
                                <IconButton href={item.twitter}>
                                  <img src={IconTwitter} alt="twiter" />
                                </IconButton>
                              </Box>
                            ) : null}
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              height: "3px",
                              background:
                                "linear-gradient(90deg, rgba(151, 36, 69, 0) 0%, #972445 49.5%, rgba(151, 36, 69, 0) 100%)",
                            }}
                          ></Box>
                          <Typography
                            color={variables.darkGreyColor}
                            variant="subtitle1"
                            fontWeight={500}
                            className="pb-4 pt-3"
                          >
                            Author of
                          </Typography>
                          {item.books.slice(0, 3).map((book, key) => {
                            return (
                              <Typography
                                key={key}
                                variant="subtitle1"
                                fontSize={16}
                                fontWeight={400}
                              >
                                {book.title}
                              </Typography>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Link>
                </SwiperSlide>
              ))}
            </SwiperWrapper>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default TrendingAuthors;
